<template>
  <div class="StaffManage-list">
    <GlobalInfoBar
        title="职员列表"
        content="主要管理平台职员账号的新增及编辑"
    />
    <GlobalChunk
        icon="search"
        title="筛选查询"
    >
      
      <div slot="filter">
        <StaffManageFrom />
      </div>

      <StaffManageTable />
    </GlobalChunk>
  </div>
</template>

<script>
import StaffManageFrom from './From'
import StaffManageTable from './Table'
export default {
  name: 'StaffManage-list',
  components: {StaffManageFrom,StaffManageTable},
  data(){
    return{
      userName:"",
      userPhone:"",
      roleId:"",
    }
  },
  methods:{
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.merchant-list{
}
</style>
