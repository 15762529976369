<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :currentPage="page.pageNum"
      :total="this.page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="头像" slot="operationImg" align="center">
        <template slot-scope="{ row }">
          <span>
            <img :src="row.userImg" width="40" height="40" />
          </span>
        </template>
      </el-table-column>
      <el-table-column label="性别" slot="operationSex" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.userSex == '00'">女</span>
          <span v-else>男</span>
        </template>
      </el-table-column>
      <el-table-column label="账号状态" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-switch
            inactive-color="#bebebe"
            @change="handleSwitch(row,'switch')"
            active-value="01"
            inactive-value="00"
            v-model="row.isEnable"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="动态口令登录码" slot="operation" align="center">
        <template slot-scope="{row}">
          <span @click="generate(row)" style="text-decoration: underline;cursor: pointer; color: rgb(9, 129, 255);">生成</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" round @click="handleBtn(row, 'edit')">编辑</el-button>
          <el-button size="mini" type="danger" round @click="handleBtn(row, 'reset')">重置密码</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <!--弹框-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="380px"
      @closed="handleDialogClosed"
    >
      <div style="font-size:16px;color:#666666;text-align:center;height:100px;line-height:100px">确认要执行该操作吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="handleSuccess">确定</el-button>
        <el-button @click.native="handleClose">取消</el-button>
      </span>
    </el-dialog>
    <!--动态二维码-->
    <el-dialog
      title="动态口令登录码"
      :visible.sync="dlogincodeVisible"
      :close-on-click-modal="false"
      width="380px"
      @closed="dlogincodeVisible=false"
    >
      <div style="font-size:12px;color:rgb(255, 104, 123);margin-bottom:20px">说明：根据职员手机号生成，职员登录需要使用自己的动态口令</div>
      <div style="text-align:center">
        <VueQr logoSrc="" :text="allData.qrcode" logoScale="" :size="150" margin=""></VueQr>
      </div>
      <span slot="footer"  class="dialog-footer stydata">
        <div class="left_fg">
          <p>账号：{{allData.key}}</p>
          <p>密钥：{{allData.secret}}</p>
        </div>
        <el-button type="primary" @click.native="dlogincodeVisible=false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import VueQr from "vue-qr"
import MinXinRequest from "@/mixins/request";
import _api from "@/utils/request";
export default {
  name: "Table",
  mixins: [MinXinRequest],
  components:{VueQr},
  data() {
    return {
      qrcode:"",
      dlogincodeVisible:false,
      recoderpagenum:1,
      seachDataList: [],
      StaffEnable: {
        id: "",
        isEnable: ""
      },
      allData:{},
      //弹窗
      dialogVisible: false, // 弹框的出现与否
      dialogTitle: "", // 标题
      dialogWidth: 380,
      dialongDecide: "",
      resetId: "", //密码重置ID
      tableColumns: [
        { label: "姓名", prop: "userName" },
        { slotName: "operationImg" },
        { label: "角色", prop: "roleName" },
        { slotName: "operationSex" },
        { label: "登录账号", prop: "userAccount" },
        { label: "联系电话", prop: "userPhone" },
        { label: "添加时间", prop: "createTime" },
        { slotName: "operation" }
      ]
    };
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  created() {
    this.handleCurrentChange();
  },
  watch:{
    SeachParams(newVal){
      console.log(newVal)
      this.handleCurrentChange()
    }
  },
  methods: {
    generate(row){
      _api.getAdminGoogleCode({userId:row.id}).then(res=>{
        if(res.code==1){
          this.dlogincodeVisible=true
          this.allData=res.data
        }
      })
    },
    // 表格切换页面
    handleCurrentChange(val) {
      this.recoderpagenum=val||1
      console.log(val,this.recoderpagenum);
      const baseRequest = {
        roleId: this.SeachParams.roleId,
        userName: this.SeachParams.userName,
        userPhone: this.SeachParams.userPhone,
        isEnable: this.SeachParams.isEnable,
        pageNum: val,
        pageSize: this.SeachParams.pageSize,
      };
      this.$emit('handleCurrentChange',val)
      _api.StaffManageList(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.$store.commit("tagsView/POST_STAFF", this.seachDataList);
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    // 表格按钮操作
    handleBtn(row, type) {
      console.log("表格操作", type, row);
      if (type === "reset") {
        this.dialogVisible = true;
        this.dialogTitle = "重置密码";
        this.dialongDecide = "reset";
        this.resetId = row.id;
      }else if(type === "edit"){
        this.$store.commit('tagsView/POST_STAFFADD_FORMDATA', row)
        this.$router.push({path:"/systemManage/StaffAdd",query:{type: 'edit',id: row.id}})
      }
    },
    //启用/禁用账号
    handleSwitch(row) {
      this.dialogVisible = true;
      this.dialogTitle = "启用/禁用账号";
      this.dialongDecide = "switch";
      this.StaffEnable.id = row.id;
      this.StaffEnable.isEnable = row.isEnable;
    },
    //弹窗取消
    handleDialogClosed() {
      console.log(this.recoderpagenum);
      this.dialogVisible = false;
      this.handleCurrentChange(this.recoderpagenum);
    },
    //弹窗确定
    handleSuccess() {
      if (this.dialongDecide == "switch") {
        _api.staffListSwitch(this.StaffEnable).then(res => {
          if (res.code === 1) {
            this.$message.success("操作成功");
          } else {
            this.$message.error(res.msg);
            this.handleCurrentChange();
          }
        });
      } else if(this.dialongDecide == "reset"){
        _api.resetPassword({ id: this.resetId }).then(res => {
          if (res.code === 1) {
            this.$message.success("密码重置成功");
          } else {
            this.$message.error(res.msg);
          }
        });
      }
      this.dialogVisible = false;
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
}
.left_fg{
  font-size: 14px;
  text-align: left;
  >p{
    margin-bottom: 10px;
  }
}
.stydata{
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
