<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      :needBtnLoading="true"
      round
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    > 
       <el-form-item class="el_from" label-width="40px" label="角色" slot="operationSelect" style="margin:0">
          <el-select v-model="roleId" placeholder="请选择" clearable @change="handleChange">
            <el-option
              v-for="item in RoleSelectList"
              :key="item.id"
              :label="item.roleName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
         <div style="text-align: right;margin-bottom: 20px" slot="AddSlot">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="$router.push('/systemManage/StaffAdd')">添加职员</el-button>
      </div>
    </GlobalForm>
  </div>
</template>

<script>
import {formValidateMobile} from '@/utils/form-validate'
import _api from "@/utils/request";
export default {
  name: 'Form',
  data() {
    return {
      roleId:"",
      RoleSelectList:[],
      initData: null,
      formItemList: [
        {
          key: 'userName',
          type: 'input',
          lableWidth: '80',
          labelName: '职员名称',
          placeholder: '请输入职员名称'
        },
        {
          key: 'userPhone',
          type: 'input',
          lableWidth: '80',
          labelName: '联系电话',
          placeholder: '请输入联系电话'
        },
        {slotName: 'operationSelect'},
        {
          key: 'isEnable',
          type: 'select',
          lableWidth: '80',
          labelName: '账号状态',
          option: [
            {
              value: true,
              label: '已启用',
            },
            {
              value: false,
              label: '已禁用',
            },
          ],
        },
        {slot:"AddSlot"}
      ],
      formRules: {
        mobile: [{
          validator: formValidateMobile,
          trigger: 'blur'
        }]
      }
    }
  },
  created() {
    _api.RoleList().then(res => {
        if (res.code === 1) {
          this.RoleSelectList = res.data;
        }
    });
  },
  methods: {
    handleChange(val) {
      this.roleId = val;
    },
    //查询
    handleConfirm(data,cd) {
      const SeachParams = {
        roleId:this.roleId,
        userPhone:data.userPhone,
        userName:data.userName,
        isEnable:data.isEnable,
        pageNum:1,
        pageSize:10,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      } 
      this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo{}
</style>
